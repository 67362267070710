import React from "react"
import { graphql } from "gatsby"
import CategoryPage from "../components/category-page";

export default ({ data }) => <CategoryPage data={data.allWpPost.nodes} category={"audiovisual"} category_name={`Audiovisuales`}/>

export const query = graphql`
{
  allWpPost(sort: { fields: modifiedGmt, order: DESC }) {
    nodes {
      uri
      id
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          id
          name
          slug
        }
      }
      title
      featuredImage {
        node {
          id
          sourceUrl
          localFile {
            childImageSharp {
              id
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      autorxs {
        authors {
          name
          customProfileImage
        }
      }
      copete {
        copete
      }
    }
  }
}
`
